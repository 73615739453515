import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  ERROR_READER_TIMEOUT,
  Exception,
  HOME_PAGE_URL,
  INTERNAL_SERVER_ERROR_CODE_ONE,
  RECOGNIZED_RESPONSE_STATUS,
  SessionDetails
} from "../../../module/AuthAdminModule";
import {Navigate, NavigateFunction, useLocation, useOutletContext} from "react-router-dom";
import {
  currentApiDomain,
  getServiceNameFromLocation,
  HEADER_INIT,
  navigateToPinReset, navigateToServices,
  REQUEST_INIT
} from "../../../util/helperUtil";
import Notification, {NotificationType} from "../../common/Notification";
import NfcTapView from "../../common/NfcTapView";
import ErrorNotification from "../../common/ErrorNotification";
import ResponsePage, {ResponsePageProps, ResponsePageType} from '../../common/ResponsePage';
import {useTranslator} from '../../../localization/Translator';
import {useNavigate} from 'react-router';

interface ReRegisterAssociateProps {
  userId: string;
}

const ReRegisterAssociate = (props: ReRegisterAssociateProps) => {
  const t = useTranslator();
  const navigate: NavigateFunction = useNavigate();
  const AUTHORIZE_URL = `${currentApiDomain}/Authorize`;
  /****  Constants  *****/
  let readerCheckCount = 1;
  const sessionDetails: SessionDetails = useOutletContext();
  const READER_CHECK_LIMIT = 7;
  const READER_RETRY_IN_MILLISECONDS = 5000;
  const OTP_DEFAULT_LENGTH = 67;
  const RE_REGISTER_ASSOCIATE = `${currentApiDomain}/ReRegisterAssociate`;

  /****  States   *****/
  const location = useLocation();
  const serviceName = getServiceNameFromLocation(location);

  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [response, setResponse] = React.useState<ResponsePageProps | null>(null);
  const [cardUid, setCardUid] = useState("");
  const [showNFCTapView, setShowNFCTapView] = useState(false);

  const onDismissModal = () => {
    setShowNFCTapView(false);
  }

  const navigateToReRegister = () => {
    window.location.reload();
  }

  const handleToken = (tokenValue: string) => {
    // Parse out "urn:" that is included from Velocity NDEF reads
    if (tokenValue.indexOf("urn:") !== -1) {
      tokenValue = tokenValue.slice(4)
    }
    if (tokenValue.length !== 0 && tokenValue.length === OTP_DEFAULT_LENGTH) {
      reRegisterAssociate(tokenValue);
    }
  }

  const reRegisterAssociate = (otp: string) => {
    fetch(RE_REGISTER_ASSOCIATE, {
      method: "POST",
      ...REQUEST_INIT,
      headers: {
        ...HEADER_INIT,
        "anti-csrftoken-a2z": sessionDetails.csrfToken,
      },
      body: JSON.stringify({
        otp: otp,
        associateAlias: props.userId,
        serviceName: serviceName,
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          // redirect to authenticate
          window.location.replace(AUTHORIZE_URL);
          return res.json();
        }
        if (RECOGNIZED_RESPONSE_STATUS.includes(res.status)) {
          if (res.status === 200) {
            setErrorCode(null);
            setResponse({
              pageType: ResponsePageType.SUCCESS,
              message: t.translate("re_registration_successful_message", {
                alias: props.userId,
              }),
              headerTitle: t.translate("re_registration_successful_header_message"),
              buttonName: t.translate("return_to_home_page_button_name"),
              buttonOnClick: navigateToServices,
              buttonName2: t.translate("pinReset"),
              buttonOnClick2: navigateToPinReset,
            });
            return;
          }
          return res.json();
        }
        throw new Error("Unrecognized response status " + JSON.stringify(res));
      })
      .then((res: any) => {
        if (!res) {
          // success
          return;
        }
        // handle recognized errors
        if (res && res.hasOwnProperty("errorId")) {
          res = res as Exception;
          console.error("Unsuccessful API call", res.message, res);
          setErrorCode(res.errorId);
          return;
        }
        // unrecognized error
        throw new Error("Unrecognized error " + JSON.stringify(res));
      })
      .catch((err) => {
        setErrorCode(INTERNAL_SERVER_ERROR_CODE_ONE);
        console.error(err.message);
      });
  };

  // Method to post messages from UI to plugin scripts
  const postMessageToPlugin = (messageObj: any) => {
    window.postMessage({...messageObj, source: "frontend"}, window.location.origin);
  }
  const startScan = () => {
    postMessageToPlugin({type: "startNFCReader"});
  }
  const connectCardReader = () => {
    postMessageToPlugin({type: "checkNFCReader"});
  }

  /****  Hook   *****/
  useEffect(() => {
    console.log("Connecting card reader...")
    connectCardReader();
    window.addEventListener("message", (event) => {
      if (event.origin === window.location.origin && event.data?.source === "plugin") {
        const message = event.data;
        if (message.type === "checkNFCReader") {
          if (message.isReaderAvailable) {
            startScan();
            setShowNFCTapView(true);
          } else {
            if (readerCheckCount < READER_CHECK_LIMIT) {
              setTimeout(function () {
                connectCardReader();
              }, READER_RETRY_IN_MILLISECONDS);
              readerCheckCount++;
            } else {
              setErrorCode(ERROR_READER_TIMEOUT);
            }
          }
        } else if (message.type === "NFCReaderTimeout") {
          console.log("Check reader request timed out...")
          setErrorCode(ERROR_READER_TIMEOUT);
        } else if (message.type === "readNDEF") {
          setCardUid(message.otp.slice(5, 19));
          handleToken(message.otp);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!sessionDetails?.csrfToken) {
    console.error("Could not find CSRF token, redirecting to home page.");
    return <Navigate to={HOME_PAGE_URL}/>;
  }

  const badgeTapPage = !response && !errorCode &&
      <>
          <div style={{width: "70%", margin: "0 auto"}}>
              <Notification notificationType={NotificationType.INFO}
                            headerI18nKey={"card_reader_check_header"}
                            messageI18nKey={"card_reader_check_description"}/>
          </div>
          <NfcTapView visible={showNFCTapView} onDismissModal={onDismissModal}></NfcTapView>
      </>;
  const errorPage = errorCode &&
      <ErrorNotification errorCode={errorCode} variables={{data: cardUid, alias: props.userId}}
                         buttonName={t.translate("back_button_label")}
                         buttonOnClick={navigateToReRegister}/>;

  const responsePage = response && <ResponsePage
      message={response.message}
      buttonName={response.buttonName}
      buttonOnClick={response.buttonOnClick}
      buttonName2={response.buttonName2}
      buttonOnClick2={response.buttonOnClick2}
      headerTitle={response.headerTitle}
      pageType={response?.pageType}/>;

  return (
    <div style={{width: "60%", margin: "0 auto"}}>
      {badgeTapPage}
      {errorPage}
      {responsePage}
    </div>
  );
}

export default ReRegisterAssociate;